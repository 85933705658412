import React from 'react'
import ReactDOM from 'react-dom/client'
import "./main.scss";

import Logo from "../src/assets/Bidaia_logo_cian.png"
import Logo_svg from "../src/assets/Logo_Bidaia.svg"


console.log("Ryuk Best Dog!!!");

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

function App() {
    return (
        <div className="coming-soon coming-soon-gradient-1" style={{}}>
            <img src={Logo} alt="logo" style={{ width: "350px", aspectRatio: "auto" }} />
            <div className="center fade-text" style={{ height: "100px" }}>
                <p style={{ fontSize: "22px", fontWeight: "bold", color: "#FFF" }}>A Whole New Travel Experience</p>
                <p style={{ color: "#FFF" }}>Coming fall 2024</p>
            </div>
        </div>
    )
}

